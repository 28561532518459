














































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  DropDownController,
  StudentController,
  CourseController,
  DiaryController,
} from '@/services/request.service'
import { toPage, guid, judgeVnode, clearEmptyArray, getSchoolInfo } from '@/utils/utils'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import TableTotal from '@/components/TableTotal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import { createPagination } from '@/constant/constant'
import { exportXlsx, writeFile } from '@/utils/xlsx'

@Component({
  components: {
    TableTotal,
    FlexTooltip,
    filterGroup,
  },
})
export default class Diary extends Vue {
  private diaryTypes: Array<any> = []
  private childTypes: Array<any> = []
  private scores: Array<any> = [
    {
      key: 1,
      value: '<0',
    },
    {
      key: 2,
      value: '0-10',
    },
    {
      key: 3,
      value: '10-20',
    },
    {
      key: 4,
      value: '>=20',
    },
  ]
  private sections: Array<any> = []
  private sectionIds = []
  private filterFold: boolean = false
  private houses: Array<any> = []
  private schoolYears: Array<any> = []
  private data: Array<any> = []
  private loading: boolean = true
  private selectedRowKeys: Array<any> = []
  private pagination: any = createPagination({})
  private filter: any = {
    primaryTypeId: 0,
    diaryEntryTypeId: 0,
    points: 0,
    sectionIds: [],
    houseId: 0,
    createTime: null,
    recordTime: null,
    name: '',
    accommodation: 0,
    studentId: '',
    gender: 'all',
    dormitoryId: [],
    selfStudyRoomId: undefined,
    schoolYearId: 0,
  }
  private dynamicColumn: Array<any> = []
  private dormitoryList: Array<any> = []
  private studyRoomList: any = []
  private exportLoading: boolean = false

  private get locale(): string {
    return this.$store.state.locale
  }

  private get scrollX(): number {
    return 760 * this.ratio + 260 + (this.schoolId === 8 ? 350 : 0)
  }

  private get formatSections(): any {
    return this.sections.map(item => {
      return {
        children: item.subOptions.map(child => ({
          key: child.key,
          title: this.locale === 'zh' ? child.value : child.enValue || child.value,
          value: child.key,
        })),
        title: `${item.value}-${item.enValue}`,
        key: `${item.enValue}_${item.extraValue}_${item.key}`,
        value: `${item.enValue}_${item.extraValue}_${item.key}`,
      }
    })
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'diary'
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh(false)
    }
  }

  private get columns(): Array<any> {
    const dynamicColumn = this.dynamicColumn.map(item => {
      return {
        dataIndex: item.diaryEntryTypeId,
        key: item.diaryEntryTypeId,
        title: this.locale === 'zh' ? item.value : item.enValue || item.value,
        align: 'left',
      }
    })
    return [
      {
        dataIndex: 'studentName',
        width: 150,
        key: 'studentName',
        title: this.$t('diary.studentNName'),
        ellipsis: true,
        fixed: 'left',
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'gender',
        width: 70,
        title: this.$t('myClass.student.gender'),
        scopedSlots: { customRender: 'gender' },
      },
      {
        dataIndex: 'className',
        key: 'className',
        ellipsis: true,
        width: 90,
        title: this.$t('diary.className'),
        scopedSlots: { customRender: 'className' },
      },
      {
        dataIndex: 'house',
        key: 'house',
        title: this.$t('diary.house'),
        width: 240 * this.ratio,
        ellipsis: true,
        scopedSlots: { customRender: 'house' },
      },
      {
        dataIndex: 'dormitoryName',
        width: 160 * this.ratio,
        title: this.$t('dormitory.room'),
        ellipsis: true,
        scopedSlots: { customRender: 'dormitoryName' },
      },
      {
        dataIndex: 'selfStudyRoomName',
        width: 160 * this.ratio,
        title: this.$t('dormitory.studyRoom'),
        ellipsis: true,
        scopedSlots: { customRender: 'studyRoom' },
      },
      // {
      //   dataIndex: 'points',
      //   key: 'points',
      //   width: 60,
      //   title: this.$t('diary.points'),
      // },
      this.schoolId !== 8
        ? {
            dataIndex: 'countNum',
            key: 'countNum',
            width: 50,
            title: this.$t('diary.countNum'),
          }
        : -1,
      this.schoolId === 8
        ? {
            dataIndex: 'housePoints',
            key: 'housePoints',
            width: 150,
            title: this.$t('diary.housePoint'),
          }
        : -1,
      this.schoolId === 8
        ? {
            dataIndex: 'conductPoints',
            key: 'conductPoints',
            width: 150,
            title: this.$t('diary.conductPoint'),
          }
        : -1,
      {
        dataIndex: 'modifyTime',
        width: 200 * this.ratio,
        title: this.$t('courseMaterial.updateTime'),
        scopedSlots: { customRender: 'updateTime' },
      },
    ].filter(item => item !== -1)
  }

  private get schoolId(): number {
    return getSchoolInfo().schoolId
  }

  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 30,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 30,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 30,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        width: 30,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 30,
      },
      {
        dataIndex: 'grade',
        title: this.$t('common.grade'),
        width: 30,
      },
      {
        dataIndex: 'advisory',
        title: this.$t('common.advisory'),
        width: 30,
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 30,
      },
      {
        dataIndex: 'dormitory',
        title: this.$t('common.dormitory'),
        width: 30,
      },
      {
        dataIndex: 'selfStudyRoom',
        title: this.$t('common.studyRoom'),
        width: 30,
      },
      {
        dataIndex: this.locale === 'zh' ? 'primaryType' : 'primaryTypeEn',
        title: this.$t('diary.diaryType'),
        width: 30,
      },
      {
        dataIndex: this.locale === 'zh' ? 'subType' : 'subTypeEn',
        title: this.$t('diary.childType'),
        width: 30,
      },
      {
        dataIndex: 'description',
        title: this.$t('common.description'),
        width: 30,
      },
      {
        dataIndex: 'points',
        title: this.$t('diary.points'),
        width: 20,
      },
      {
        dataIndex: 'createTime',
        title: this.$t('common.createTime'),
        width: 30,
      },
      {
        dataIndex: 'recordTime',
        title: this.$t('diary.recordTime'),
        width: 30,
      },
    ]
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private created(): void {
    this.getFilters()
  }

  private changeTime(date, dateString): void {
    if (date.length === 0) {
      this.filter.createTime = null
    }
    this.getData()
  }

  private changeRecordTime(date, dateString): void {
    if (date.length === 0) {
      this.filter.recordTime = null
    }
    this.getData()
  }

  private changeDiaryType(): void {
    this.filter.diaryEntryTypeId = 0
    const primaryTypeId = this.filter.primaryTypeId
    DiaryController.getDiaryEntryTypes(primaryTypeId)
      .then(res => {
        this.childTypes = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => this.getData())
  }

  private getFilters(): void {
    Promise.all([
      DiaryController.getPrimaryTypes(),
      DropDownController.getSectionCascade(),
      DropDownController.getHouseGroupListAll(),
      DropDownController.getFloorAndDormitoryList(),
      DropDownController.getSsRoomList(),
      DropDownController.getSchoolYearList(),
    ])
      .then(res => {
        this.diaryTypes = res[0].data
        this.sections = res[1].data
        this.houses = res[2].data
        this.dormitoryList = clearEmptyArray(res[3].data)
        this.studyRoomList = res[4].data
        this.schoolYears = res[5].data
        this.filter.schoolYearId = this.schoolYears[0].key
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private reset(): void {
    this.filter = {
      primaryTypeId: 0,
      diaryEntryTypeId: 0,
      points: 0,
      sectionId: 0,
      houseId: 0,
      createTime: null,
      name: '',
      sectionIds: [],
      accommodation: 0,
      schoolYearId: this.schoolYears[0].key,
    }
    this.sectionIds = []
    this.$set(this.pagination, 'current', 1)
    this.refresh(true)
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination, false)
  }

  private getData(
    page = { pageSize: this.pagination.defaultPageSize, current: 1 },
    isClear = true
  ): void {
    isClear ? (this.selectedRowKeys = []) : ''
    this.loading = true
    const {
      primaryTypeId,
      diaryEntryTypeId,
      points,
      sectionIds,
      houseId,
      name,
      createTime,
      recordTime,
      accommodation,
      studentId,
      gender,
      schoolYearId,
    } = this.filter
    const request = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      primaryTypeId: primaryTypeId || undefined,
      diaryEntryTypeId: diaryEntryTypeId || undefined,
      points: points || undefined,
      sectionId: sectionIds,
      houseId: houseId || undefined,
      name: name ? name.trim() : undefined,
      start: createTime ? moment(createTime[0]).valueOf() : undefined,
      end: createTime ? moment(createTime[1]).valueOf() : undefined,

      //发生时间
      recordTimeStart: recordTime ? moment(recordTime[0]).valueOf() : undefined,
      recordTimeEnd: recordTime ? moment(recordTime[1]).valueOf() : undefined,

      studentNum: studentId?.trim() || undefined,
      dormitory: accommodation === 0 ? undefined : accommodation === 1,
      gender: gender === 'all' ? undefined : gender,
      dormitoryId: this.filter.dormitoryId?.[1],
      selfStudyRoomId: this.filter.selfStudyRoomId,
      schoolYearId,
    }
    DiaryController.getStudentDiaryEntries(request)
      .then(res => {
        this.data = res.data.items.map(item => {
          const dynamic = item.diaryEntryStatistics
          let stat = {} as any
          dynamic.forEach(item => {
            stat[item.diaryEntryTypeId] = item.countNum
          })
          return {
            ...item,
            ...stat,
          }
        })
        this.dynamicColumn = cloneDeep(
          res.data.items[0] ? res.data.items[0].diaryEntryStatistics : []
        )
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh(false)
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private exportDiary(): void {
    this.exportLoading = true
    const {
      primaryTypeId,
      diaryEntryTypeId,
      sectionIds,
      houseId,
      name,
      createTime,
      recordTime,
      gender,
      selfStudyRoomId,
      dormitoryId,
      schoolYearId,
    } = this.filter
    const request = {
      houseId: houseId || undefined,
      name: name ? name.trim() : undefined,
      gender: gender === 'all' ? undefined : gender,
      dormitoryId: dormitoryId?.[1],
      primaryTypeId: primaryTypeId || undefined,
      diaryEntryTypeId: diaryEntryTypeId || undefined,
      start: createTime ? moment(createTime[0]).valueOf() : undefined,
      end: createTime ? moment(createTime[1]).valueOf() : undefined,
      recordTimeStart: recordTime ? moment(recordTime[0]).valueOf() : undefined,
      recordTimeEnd: recordTime ? moment(recordTime[1]).valueOf() : undefined,
    }
    DiaryController.getDiaryExport(
      schoolYearId,
      sectionIds,
      undefined,
      request.houseId,
      request.name,
      request.gender,
      selfStudyRoomId,
      request.dormitoryId,
      request.primaryTypeId,
      request.diaryEntryTypeId,
      request.start,
      request.end,
      request.recordTimeStart,
      request.recordTimeEnd
    )
      .then(async res => {
        const diaryList = res.data
          .map(item => ({
            ...item,
            points: item.points === null ? '' : item.points,
            recordTime: moment(item.recordTime).format('YYYY-MM-DD HH:mm'),
            createTime: moment(item.createTime).format('YYYY-MM-DD HH:mm'),
          }))
          .sort((a, b) => {
            return a.lastName.charCodeAt(0) - b.lastName.charCodeAt(0)
          })
        const file = exportXlsx(
          this.excelColumns,
          this.selectedRowKeys.length
            ? diaryList.filter(diary => this.selectedRowKeys.includes(diary.studentId))
            : diaryList
        )
        const worksheet = file.getWorksheet(1)

        worksheet.eachRow(row => {
          row.height = 30
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })

        await writeFile(file, `Student Log Detail.xlsx`)

        this.exportLoading = false
      })
      .catch(err => {
        console.log(err)
        this.exportLoading = false
      })
  }

  private viewDetail(studentId): void {
    this.$router.push({ name: 'diaryDetail', params: { studentId } })
  }

  private addDiary(): void {
    // if (!this.selectedRowKeys.length) {
    //   this.$message.error(this.$tc('common.batchSelectError'))
    //   return
    // }
    this.$store.commit('setDiaryStudents', this.selectedRowKeys)
    this.$router.push({ name: 'diaryEditor', query: { type: 'add' } }).catch(err => {})
  }

  private clearSelection(): void {
    this.selectedRowKeys = []
  }

  private onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys
  }

  private refresh(isClear): void {
    this.getData(this.pagination, isClear)
  }

  private onSectionChange(value, label, extra) {
    const isVNode = judgeVnode(extra.triggerNode)
    this.filter.sectionIds = (extra.allCheckedNodes || [])
      .map(item => {
        if (!isVNode) {
          if (item.children) {
            return item.children.map(item => item.node.key)
          } else {
            return item.node.key
          }
        } else {
          if (item.componentOptions.children.length) {
            return item.componentOptions.children.map(item => item.data.key)
          } else {
            return item.data.key
          }
        }
      })
      .flat()
    this.fetchData()
  }
}
