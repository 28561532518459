


















































































































































































































































































































































import moment from 'moment'
import { fileUploader } from '@/services/qiniu.service'
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import InjuredMark from '@/components/InjuredMark.vue'
import { DiaryController, DropDownController, TeacherController } from '@/services/request.service'
import GroupTransfer from '@/components/GroupTransfer.vue'
import { removeDuplicateTeacher, getSchoolInfo } from '@/utils/utils'
import Transfer from '@/components/Transfer.vue'
import cloneDeep from 'lodash/cloneDeep'
import Upload from '@/components/Upload.vue'
Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    InjuredMark,
    GroupTransfer,
    Transfer,
    Upload,
  },
})
export default class DiaryEditor extends Vue {
  private visible: Array<boolean> = []
  private saved: boolean = false
  private selectedStudentsEnd: Array<any> = []
  private allStudents: Array<any> = []
  private selectedParents: Array<any> = []
  private attachments: Array<any> = []
  private transferVisible: boolean = false
  private ifShareParents: boolean = false
  private ifShareStudents: boolean = false
  private recipient: Array<any> = []
  private students: Array<any> = []
  private teachers: any
  private transferTitle = ''
  private fileList: Array<any> = []
  private groupTransferVisible: boolean = false
  private transferCandidate: any = {}
  private transferSelected: Array<any> = []
  private saveLoading: boolean = false
  private diaryTypes: Array<any> = []
  private childTypes: Array<any> = []
  private tagContainer: Array<any> = []
  private showReceipt: boolean = false
  private nurseDiaryFlag: boolean = false
  private selectedStudents: Array<any> = []
  private selectedTeachers: Array<any> = []
  private originTeachers: Array<any> = []
  private studentLoading: any = false
  private teacherLoading: any = false
  private InfoForm: any
  private type: any = 'add'
  private diaryId
  private uploading: any = false
  private formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get filteredOriginTeachers(): any {
    return this.originTeachers.filter(it => !it.selected).map(it => it.id)
  }

  private currentChildType(): any {
    const diaryForm = this.InfoForm
    const currentChildTypeId = diaryForm.getFieldValue('diaryEntryTypeId')
    return this.childTypes.find(item => item.key === currentChildTypeId)
  }

  private created(): void {}

  private judeDiaryType(rule, value, callback) {
    if (!value) {
      callback(this.$t('diary.pleaseSelect'))
    } else {
      callback()
    }
  }

  private get preSelectedStudents(): Array<any> {
    return this.$store.state.diaryStudents
  }

  private loadEditValue(): void {
    DiaryController.getDiaryEntry(parseInt(this.diaryId, 10))
      .then(res => {
        // 载入数据
        if (res.data.primaryTypeId === 4) {
          this.nurseDiaryFlag = true
        } else {
          this.nurseDiaryFlag = false
        }
        // let diaryEntryType = res.data.diaryEntryType
        DiaryController.getDiaryEntryTypes(res.data.primaryTypeId)
          .then(res => {
            this.childTypes = res.data
          })
          .catch(err => {
            console.error(err)
          })
        this.InfoForm.setFieldsValue({
          primaryTypeId: res.data.primaryTypeId,
          diaryEntryTypeId: res.data.diaryEntryTypeId,
          location: res.data.location,
          date: moment(res.data.recordTime),
          time: moment(res.data.recordTime),
          description: res.data.description,
          points: res.data.diaryEntryTypeId === 112 ? 0 : res.data.points,
          // 成都可编辑
          shareWithParents: this.schoolId === 8 ? res.data.shareWithParents : false,
          shareWithStudents: this.schoolId === 8 ? res.data.shareWithStudents : false,
          // shareWithHeadTeachers: res.data.shareWithHeadTeachers,
          // shareWithTutors: res.data.shareWithTutors,
          // notice: res.data.notice,
          // 禁用日记分享
          // shareWithParents: false,
          // shareWithStudents: false,
          shareWithHeadTeachers: false,
          shareWithTutors: false,
          // notice: false,

          attachments: res.data.enclosures.map(item => {
            return {
              uid: item.resourceId,
              url: item.resourceUrl,
              name: item.resourceName,
            }
          }),
        })
        this.tagContainer = res.data.injureParts
        this.visible = this.tagContainer.map(item => false)

        // 禁用收件人
        // 是否显示收件人
        // if (!res.data.notice) {
        //   this.showReceipt = false
        // } else {
        //   this.showReceipt = true
        // }

        // this.selectedStudents = res.data.students.map(item => {
        //   return this.students.find(student => student.studentId = item.studentId) || {}
        // })
        this.selectedStudents = res.data.students.map(item => {
          let student = {
            id: item.studentId,
            title: item.name,
            type: 'student',
            selected: true,
            relation: '',
          }
          this.students.forEach(sclass => {
            let s = sclass.children.find(s => {
              return s.id === item.studentId
            })
            if (s) this.$set(student, 'parents', s.parents)
          })
          return student
        })
        this.originTeachers = res.data.addressee.map(item => {
          return {
            id: item.id,
            title: item.name,
            type: 'teacher',
            selected: true,
            relation: '',
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeShareParent(e): void {
    if (e.target.value) {
      this.ifShareParents = true
      this.selectedParents = []
      for (let i = 0; i < this.selectedStudents.length; i++) {
        for (let j = 0; j < (this.selectedStudents[i].parents || {}).length; j++) {
          let item = cloneDeep(this.selectedStudents[i].parents[j])
          item.relation = item.relationship
          item.value = this.selectedStudents[i].title + this.$t('relation.' + item.relationship)
          item.type = 'parent'
          item.id = this.selectedStudents[i].id + '-' + item.parentId
          item.studentId = this.selectedStudents[i].id
          this.selectedParents.push(item)
        }
      }
    } else {
      this.ifShareParents = false
    }
  }

  private changeShareStudents(e): void {
    if (e.target.value) {
      this.selectedStudentsEnd = cloneDeep(this.selectedStudents)
      this.ifShareStudents = true
    } else {
      this.ifShareStudents = false
    }
  }

  private getSelectedData(selectedData): void {
    this.selectedStudents = cloneDeep(selectedData)
    this.selectedStudentsEnd = JSON.parse(JSON.stringify(this.selectedStudents))
    // 构造父母的数据
    this.selectedParents = []
    for (let i = 0; i < this.selectedStudents.length; i++) {
      for (let j = 0; j < (this.selectedStudents[i].parents || {}).length; j++) {
        let item = cloneDeep(this.selectedStudents[i].parents[j])
        item.relation = item.relationship
        item.value = this.selectedStudents[i].title + this.$t('relation.' + item.relationship)
        item.type = 'parent'
        item.id = this.selectedStudents[i].id + '-' + item.parentId
        item.studentId = this.selectedStudents[i].id
        this.selectedParents.push(item)
      }
    }
  }

  private getSelectedDataTeacher(selectedData): void {
    this.selectedTeachers = this.selectedTeachers.concat(cloneDeep(selectedData))
  }

  private getTagInfo(Info): void {
    this.tagContainer = Info
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getData(): void {
    this.getPrimaryTypes()
    this.getStudents()
    this.getTeachers()
  }

  private getTeachers(): void {
    this.teacherLoading = true
    Promise.all([
      TeacherController.getListAll(),
      DropDownController.getHeadTeachers(),
      DropDownController.getCourseTeachers(),
      DropDownController.getTutors(),
    ])
      .then(res => {
        const allTeachers = res[0].data.map(teacher => {
          return {
            title: teacher.enName + teacher.name,
            id: teacher.teacherId,
            selected: false,
            type: 'teacher',
            relation: '',
          }
        })
        const headTeachers = res[1].data.map((grade, index) => {
          return {
            id: index,
            expand: false,
            title: grade.groupLabel || this.$t('transfer.ungrouped'),
            children: grade.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        const subjectTeachers = res[2].data.map((subject, index) => {
          return {
            id: index,
            expand: false,
            title: subject.groupLabel || this.$t('transfer.ungrouped'),
            children: subject.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        const tutors = res[3].data.map((advisory, index) => {
          return {
            id: index,
            expand: false,
            title: advisory.groupLabel || this.$t('transfer.ungrouped'),
            children: advisory.list.map(teacher => {
              return {
                id: teacher.teacherId,
                title: teacher.teacherName,
                titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
              }
            }),
          }
        })
        // let otherTeachers = [{
        //   id: 0,
        //   expand: false,
        //   title: this.$t('transfer.ungrouped'),
        //   children: removeDuplicateTeacher(allTeachers,headTeachers,subjectTeachers),
        // }]
        this.teachers = {
          all: {
            list: allTeachers,
            transferType: 'normal',
          },
          grade: {
            list: headTeachers,
            transferType: 'group',
          },
          advisory: {
            list: tutors,
            transferType: 'group',
          },
          subjectClass: {
            list: subjectTeachers,
            transferType: 'group',
          },
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.teacherLoading = false))
  }

  private getStudents(): void {
    this.studentLoading = true
    // DropDownController.getAuthStudentsGroupByClass(['1014'])
    DropDownController.getStudentsGroupByClass(false)
      .then(res => {
        this.students = res.data.map(item => {
          return {
            id: item.key,
            title: item.value || this.$t('transfer.undivided'),
            expand: false,
            children: item.subOptions.map(student => {
              return {
                id: student.key,
                title: student.value,
                // parents: student.parents || [],
                type: 'student',
                relation: '',
              }
            }),
          }
        })

        if (this.type === 'add') {
          this.allStudents = this.students.map(item => item.children).flat()
          this.selectedStudents = this.allStudents.filter(item =>
            this.preSelectedStudents.includes(item.id)
          )
          // 待优化
          for (let i = 0; i < this.selectedStudents.length; i++) {
            for (let j = 0; j < (this.selectedStudents[i].parents || {}).length; j++) {
              let item = cloneDeep(this.selectedStudents[i].parents[j])
              item.relation = item.relationship
              item.value = this.selectedStudents[i].title + this.$t('relation.' + item.relationship)
              item.type = 'parent'
              item.id = this.selectedStudents[i].id + '-' + item.parentId
              item.studentId = this.selectedStudents[i].id
              this.selectedParents.push(item)
            }
          }
          this.selectedStudentsEnd = JSON.parse(JSON.stringify(this.selectedStudents))
        } else if (this.type === 'edit') {
          this.loadEditValue()
        }
      })
      .catch(err => console.log(err))
      .finally(() => (this.studentLoading = false))
  }

  private getPrimaryTypes(): void {
    DiaryController.getPrimaryTypes(false, false)
      .then(res => {
        this.diaryTypes = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeDiaryType(value): void {
    this.InfoForm.setFieldsValue({ diaryEntryTypeId: undefined })
    let primaryTypeId = value
    if (primaryTypeId === 4) {
      this.nurseDiaryFlag = true
    } else {
      this.nurseDiaryFlag = false
    }
    DiaryController.getDiaryEntryTypes(primaryTypeId)
      .then(res => {
        this.childTypes = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private addInjuredInfo(e): void {
    const x = e.offsetX
    const y = e.offsetY
    let percentX
    let percentY
    let width = window.screen.width
    if (navigator.userAgent.includes('Windows') && width < 1920) {
      percentX = Number(((x / 360) * window.devicePixelRatio * 100).toFixed(2))
      percentY = Number(((y / 360) * window.devicePixelRatio * 100).toFixed(2))
    } else {
      percentX = Number(((x / 360) * 100).toFixed(2))
      percentY = Number(((y / 360) * 100).toFixed(2))
    }
    let item = { axisX: percentX, axisY: percentY, description: '' }
    this.tagContainer.push(item)
    this.visible.push(true)
  }

  private changeShareEmail(e): void {
    if (!e.target.value) {
      this.showReceipt = false
    } else {
      this.showReceipt = true
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private validatePoint(value): void {
    const currentChildType = this.childTypes.find(item => item.key === value)
    const disable =
      (currentChildType || {}).highPoints === 0 && (currentChildType || {}).lowPoints === 0
    if (disable) {
      this.InfoForm.setFieldsValue({ points: undefined })
    } else if (currentChildType && currentChildType.lowPoints) {
      this.InfoForm.setFieldsValue({ points: currentChildType.lowPoints })
    }
  }
  private validateFormPoint(rule, value, cb): void {
    const childTypeKey = this.InfoForm.getFieldValue('diaryEntryTypeId')
    const currentChildType = this.childTypes.find(item => item.key === childTypeKey)
    if (
      this.schoolId === 8 &&
      (value > (currentChildType || {}).highPoints || value < (currentChildType || {}).lowPoints)
    ) {
      cb(
        this.$t('tips.overSize', {
          min: (currentChildType || {}).lowPoints,
          max: (currentChildType || {}).highPoints,
        })
      )
    } else {
      cb()
    }
  }

  private validatePointOnChange(value): void {
    const childTypeKey = this.InfoForm.getFieldValue('diaryEntryTypeId')
    const currentChildType = this.childTypes.find(item => item.key === childTypeKey)
    if (
      this.schoolId === 8 &&
      (value > (currentChildType || {}).highPoints || value < (currentChildType || {}).lowPoints)
    ) {
      // cb(this.$t('tips.oversize', {min: (currentChildType || {}).lowPoints, max: (currentChildType || {}).highPoints}))
      console.log((currentChildType || {}).lowPoints, (currentChildType || {}).highPoints)
      this.InfoForm.setFields({
        ['points']: {
          value: (currentChildType || {}).lowPoints,
          errors: [
            {
              message: this.$t('tips.overSize', {
                min: `${(currentChildType || {}).lowPoints}`,
                max: `${(currentChildType || {}).highPoints}`,
              }),
              field: 'points',
            },
          ],
        },
      })
    }
  }

  private saveDiary(): void {
    this.saveLoading = true
    const diaryForm = this.InfoForm
    diaryForm.validateFields((err: any, values: any) => {
      if (err) {
        this.saveLoading = false
        return
      } else if (this.selectedStudents.length === 0) {
        this.$message.error(this.$tc('diary.selectStudents'))
        this.saveLoading = false
        return
      } else if (!diaryForm.getFieldValue('time')) {
        this.$message.error(this.$tc('diary.selectTime'))
        this.saveLoading = false
        return
      } else {
        values.students = this.selectedStudents.map(item => item.id)
        if (this.showReceipt) {
          if (!this.ifShareStudents) {
            this.selectedStudentsEnd = []
          }
          if (!this.ifShareParents) {
            this.selectedParents = []
          }
          values.addressee = this.selectedStudentsEnd.concat(
            this.selectedTeachers,
            this.selectedParents.map(parent => ({
              ...parent,
              id: (parent.id || '').split('-')[1],
            }))
          )
          if (
            !values.addressee.length &&
            !values.shareWithHeadTeachers &&
            !values.shareWithTutors
          ) {
            this.$message.error(this.$tc('diary.selectReceiver'))
            this.saveLoading = false
            return
          }
        } else {
          values.addressee = this.selectedTeachers.filter(
            item => !this.originTeachers.map(item => item.id).includes(item.id)
          )
          values.shareWithHeadTeachers = false
          values.shareWithTutors = false
        }
        values.injureParts = this.tagContainer
        values.enclosures = values.attachments.map(item => item.uid)
        if (this.type === 'edit') {
          values.diaryEntryId = parseInt(this.diaryId, 10)
        }
        let date = this.InfoForm.getFieldValue('date')
        let time = this.InfoForm.getFieldValue('time')
        values.recordTime = moment(
          moment(date).format('YYYY/MM/DD') + ' ' + moment(time).format('HH:mm:ss')
        ).valueOf()
        values.diaryEntryTypeId = values.diaryEntryTypeId ? values.diaryEntryTypeId : undefined
        values.notice = true
        DiaryController.saveDiary(values)
          .then(res => {
            this.$message.success(this.$tc('common.submitSuccess'))
            this.saved = true
            this.$route.meta.refresh = true
            this.$router.back()
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveLoading = false
          })
      }
    })
  }

  private addStudent(): void {
    this.groupTransferVisible = true
    this.transferTitle = this.$t('diary.addStudent') as string
    this.transferCandidate = this.students
    this.transferSelected = this.selectedStudents
  }

  private addTeacher(): void {
    this.transferVisible = true
    this.transferTitle = this.$t('diary.addTeacher') as string
    this.transferCandidate = this.teachers
    this.transferSelected = []
  }

  private beforeCreate(): void {
    this.InfoForm = this.$form.createForm(this)
  }

  private removeSelectedStudent(id): void {
    const index = this.selectedStudents.findIndex(item => item.id === id)
    this.selectedStudents.splice(index, 1)
    this.selectedStudentsEnd.splice(index, 1)
    this.selectedParents = this.selectedParents.filter(item => item.studentId !== id)
  }

  private removeSelectedStudentEnd(id): void {
    const index = this.selectedStudentsEnd.findIndex(item => item.id === id)
    this.selectedStudentsEnd.splice(index, 1)
  }

  private removeSelectedParents(id): void {
    const index = this.selectedParents.findIndex(item => item.id === id)
    this.selectedParents.splice(index, 1)
  }

  private removeSelectedTeacher(id): void {
    const index = this.selectedTeachers.findIndex(item => item.id === id)
    this.selectedTeachers.splice(index, 1)
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.type = to.query.type || 'add'
    this.diaryId = to.query.diaryId
    to.meta.routerName = this.type === 'edit' ? 'editDiary' : 'addDiary'
    this.getData()
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.saved) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
}
