import { render, staticRenderFns } from "./DiaryEditor.vue?vue&type=template&id=22bc3577&"
import script from "./DiaryEditor.vue?vue&type=script&lang=ts&"
export * from "./DiaryEditor.vue?vue&type=script&lang=ts&"
import style0 from "./DiaryEditor.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports