















































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { DiaryController, FeatureController } from '@/services/request.service'
import InjuredMark from '@/components/InjuredMark.vue'
import moment from 'moment'
import TableTotal from '@/components/TableTotal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import { toPage, getSchoolInfo } from '@/utils/utils'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    InjuredMark,
    TableTotal,
    FlexTooltip,
    filterGroup,
  },
})
export default class Diary extends Vue {
  private visible: Array<boolean> = []
  private isNurse: boolean = false
  private isAttendError: boolean = true
  private selectedRowKeys: Array<any> = []
  private students: any = []
  private parents: any = []
  private teachers: any = []
  private modalInfo: any = {}
  private studentInfo: any = {}
  private modalVisible: boolean = false
  private dataSource: Array<any> = []
  private loading: boolean = false
  private diaryTypes: Array<any> = []
  private childTypes: Array<any> = []
  private studentId: any
  private data: any
  private filter: any = {
    primaryTypeId: 0,
    diaryEntryTypeId: 0,
    createTime: null,
  }
  private pagination: any = createPagination({})
  private recordVisible = false
  private modifyRecord: Array<any> = []
  private permission = false

  private moment = moment

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'primaryType',
        title: this.$t('diary.diaryType'),
        scopedSlots: { customRender: 'primaryType' },
      },
      {
        key: 'diaryEntryType',
        title: this.$t('diary.childType'),
        scopedSlots: { customRender: 'diaryEntryType' },
      },
      {
        // dataIndex: 'description',
        key: 'description',
        title: this.$t('diary.description'),
        ellipsis: true,
        width: 300,
        scopedSlots: { customRender: 'description' },
      },
      {
        dataIndex: 'points',
        key: 'points',
        title: this.$t('diary.points'),
        scopedSlots: { customRender: 'points' },
      },
      // {
      //   // dataIndex: 'notice',
      //   key: 'notice',
      //   title: this.$t('diary.sendEmail'),
      //   scopedSlots: { customRender: 'notice' },
      // },
      {
        dataIndex: 'modifier',
        title: this.$t('common.operator'),
        scopedSlots: { customRender: 'operator' },
      },
      {
        // dataIndex: 'recordTime',
        key: 'recordTime',
        title: this.$t('common.operationTime'),
        scopedSlots: { customRender: 'recordTime' },
      },
      {
        title: this.$t('diary.modifyRecord'),
        scopedSlots: { customRender: 'modifyRecord' },
      },
      {
        // dataIndex: 'diaryEntryType',
        key: 'operations',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
        width: 100,
      },
    ]
  }

  private created(): void {
    this.$nextTick(() => {
      this.getPrimaryTypes()
      this.getData()
      this.getPermission()
    })
  }

  private getPermission(): void {
    FeatureController.hasPermission(this.studentId, { headers: { 'X-Request-For': '2010' } })
      .then(res => {
        this.permission = res.data.value
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeTime(date, dateString): void {
    if (date.length === 0) {
      this.filter.createTime = null
    }
    this.getData()
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'diaryDetail'
  }

  private closeModal(): void {
    this.modalVisible = false
  }

  private viewDetail(diaryEntryId): void {
    DiaryController.getDiaryEntry(diaryEntryId)
      .then(res => {
        // 将日记簿详情贴进去
        this.modalInfo = res.data
        this.visible = this.modalInfo.injureParts.map(item => false)
        this.students = this.modalInfo.addressee.filter(item => item.type === 'student')
        this.parents = this.modalInfo.addressee.filter(item => item.type === 'parent')
        this.teachers = this.modalInfo.addressee.filter(item => item.type === 'teacher')
        if (this.modalInfo.shareWithParents) {
          this.modalInfo.parentsName = this.parents
            .map(item => item.name + this.$t('relation.' + item.relation))
            .join('、')
        }
        if (this.modalInfo.shareWithStudents) {
          this.modalInfo.studentsNameEnd = this.students.map(item => item.name).join('、')
        }
        this.modalInfo.teachersName = this.teachers.map(item => item.name).join('、')
        this.modalInfo.studentsName = this.modalInfo.students.map(item => item.name).join('、')

        if (res.data.primaryTypeId === 7) {
          this.isAttendError = false
        } else {
          this.isAttendError = true
        }
        if (res.data.primaryTypeId === 4) {
          this.isNurse = true
        } else {
          this.isNurse = false
        }
        this.modalVisible = true
      })
      .catch(err => {
        console.error(err)
      })
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private addDiary(): void {
    this.selectedRowKeys.push(Number(this.studentId))
    this.$store.commit('setDiaryStudents', this.selectedRowKeys)
    this.$router.push({ name: 'diaryEditor', query: { type: 'add' } }).catch(err => {})
  }

  private deleteRecord(index): void {
    // 删除
    DiaryController.deleteDiary(index, this.studentId)
      .then(res => {
        this.$message.success(this.$tc('common.deleteSuccess'))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.$set(this.pagination, 'current', 1)
        this.refresh()
      })
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private reset(): void {
    this.filter = {
      primaryTypeId: 0,
      diaryEntryTypeId: 0,
      createTime: null,
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private edit(diaryId): void {
    this.$router.push({ name: 'diaryEditor', query: { type: 'edit', diaryId } })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private getPrimaryTypes(): void {
    DiaryController.getPrimaryTypes()
      .then(res => {
        this.diaryTypes = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeDiaryType(): void {
    this.$set(this.filter, 'diaryEntryTypeId', 0)
    const primaryTypeId = this.filter.primaryTypeId
    DiaryController.getDiaryEntryTypes(primaryTypeId)
      .then(res => {
        this.childTypes = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => this.getData())
  }

  private async getStudentDiaryInfo(studentId): Promise<any> {
    const res = await DiaryController.getStudentDiaryInfo(studentId)
    this.studentInfo = res.data
  }

  private async getData(page = { current: 1, pageSize: 50 }): Promise<any> {
    if (this.loading) return
    this.loading = true
    this.studentId = this.$route.params.studentId
    await this.getStudentDiaryInfo(this.studentId).catch(err => console.error(err))
    const { primaryTypeId, diaryEntryTypeId, createTime } = this.filter
    const request = {
      studentId: this.studentId,
      pageCurrent: page.current,
      pageSize: page.pageSize,
      primaryTypeId: primaryTypeId || undefined,
      diaryEntryTypeId: diaryEntryTypeId || undefined,
      start: createTime && createTime.length ? moment(createTime[0]).valueOf() : undefined,
      end: createTime && createTime.length ? moment(createTime[1]).valueOf() : undefined,
    }

    DiaryController.getDiaryEntrySummary(
      request.pageCurrent,
      request.pageSize,
      request.studentId,
      request.primaryTypeId,
      request.diaryEntryTypeId,
      request.start,
      request.end
    )
      .then(res => {
        this.dataSource = res.data?.diaryEntryItemResponsePagedList?.items
        this.$set(this.studentInfo, 'housePoints', res.data.housePoints)
        this.$set(this.studentInfo, 'conductPoints', res.data.conductPoints)
        this.pagination.total = res.data?.diaryEntryItemResponsePagedList?.totalItem
        this.pagination.current = page.current
        toPage(page, this.dataSource, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private clearSelection(): void {
    this.selectedRowKeys = []
  }

  private showRecord(responseList): void {
    this.modifyRecord = responseList
    this.recordVisible = true
  }

  private formatRecord(record): string {
    let output = [] as Array<string>
    if (record.initial) {
      const user = record.displayName
      const type =
        this.locale === 'zh'
          ? `${record.recordPrimaryType}-${record.recordType}`
          : `${record.recordPrimaryEnType}-${record.recordEnType}`
      const time = this.$d(moment(record.recordTime).toDate(), 'short')
      const desc = record.recordDesc
      const point = record.recordPoint
      output.push(
        `${this.$t('diary.modifyRecordText.createDiary', { user, type, time, desc, point })}`
      )
    } else {
      if (record.recordDesc) {
        const type = this.$t('diary.modifyRecordText.recordDesc')
        const value = record.recordDesc
        output.push(`${this.$t('diary.modifyRecordText.changeTo', { type, value })}`)
      }
      if (record.recordPoint !== null) {
        const type = this.$t('diary.modifyRecordText.recordPoint')
        const value = record.recordPoint
        output.push(`${this.$t('diary.modifyRecordText.changeTo', { type, value })}`)
      }
      if (record.recordPrimaryType) {
        const type = this.$t('diary.modifyRecordText.recordPrimaryType')
        const value = this.locale === 'zh' ? record.recordPrimaryType : record.recordPrimaryEnType
        output.push(`${this.$t('diary.modifyRecordText.changeTo', { type, value })}`)
      }
      if (record.recordType) {
        const type = this.$t('diary.modifyRecordText.recordType')
        const value = this.locale === 'zh' ? record.recordType : record.recordEnType
        output.push(`${this.$t('diary.modifyRecordText.changeTo', { type, value })}`)
      }
      if (record.recordTime) {
        const type = this.$t('diary.modifyRecordText.recordTime')
        const value = this.$d(moment(record.recordTime).toDate(), 'short')
        output.push(`${this.$t('diary.modifyRecordText.changeTo', { type, value })}`)
      }
    }
    return output.join(', ')
  }
}
